<template>
  <section class="app-container">
    <Filter
      ref="filter"
      @search="handleSearch"
      @export-click="exportClick"
      @refresh-table="handleResetTable"
    />
    <SkillList ref="skillListRef" @setting="settingHandler"></SkillList>
  </section>
</template>
<script>
import { defineComponent, ref } from "vue";
import Filter from "./components/Filter.vue";
import SkillList from "./components/List";
import { downloadFile } from "@/utils/common";
import { useGlobalPropertyHook } from "@/hooks/common";


export default defineComponent({
  name: "exportData",
  components: {
    SkillList,
    Filter,
  },
  setup() {
    const { $api } = useGlobalPropertyHook();

    const skillListRef = ref();
    const filter = ref()
    const handleSearch = (searchParams) => {
      skillListRef.value.searchClick(searchParams);
    };
    const handleResetTable = (searchParams) => {
      skillListRef.value.doSearch(searchParams);
    };

    const exportClick = (searchParams) => {
      $api.resourceExport(searchParams).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };

    const settingHandler = () => {
      filter.value.handleSetting()
    }
    return {
      skillListRef,
      handleSearch,
      handleResetTable,
      exportClick,
      filter,
      settingHandler
    };
  },
});
</script>
