<template>
  <div ref="customEntryRefModal">
    <a-modal :visible="visible" :getContainer="() => $refs.customEntryRefModal" title="自定义表头" width="40.6vw" centered
      @cancel="closeModal">

      <div class="box-main">
        <div class="box-item box-left">
          <div class="tt">
            <span>表头字段</span>
            <span class="num">{{ leftData.length }}</span>
          </div>
          <div class="search">
            <a-input-search allowClear v-model:value="leftValue" placeholder="搜索" style="width: 100%" @change="clearLeft"
              @search="onSearchLeft" />
          </div>
          <div class="tree-box box-margin">
            <a-table :row-selection="{ selectedRowKeys: checkedKeys, onChange: tableSelectChange }"
              :dataSource="leftValue ? searchData : leftData" :columns="leftColumns" rowKey="firstMenuId"
              :pagination="false">
            </a-table>
          </div>
        </div>
        <div class="box-item box-right">
          <div class="tt">
            <span>已选择的表头</span>
            <span class="num back-color">{{ dataList.length }}</span>
          </div>
          <div class="search">
            <a-input-search allowClear v-model:value="rightValue" placeholder="搜索" style="width: 100%"
              @change="clearRight" @search="onSearchRight" />
          </div>
          <div class="tree-box box-margin">
            <a-table class="specialTable" :customRow="customRow" rowKey="firstMenuId"
              :dataSource="rightValue ? searchDataList : dataList" :columns="columns" :pagination="false">
              <template #action="{ record, index }">
                <a-space class="flex-box">
                  <Tooltip :title="'删除'">
                    <span class="operationLinkdel icon-btn" @click="handleDel(record, index)">
                      <CloseCircleFilled :style="{ display: 'block', color: '#B3B3B3' }" />
                    </span>
                  </Tooltip>
                </a-space>
              </template>
            </a-table>
          </div>
        </div>
      </div>

      <template #footer>
        <div class="btn-left">
          <Tooltip :title="'重置恢复默认值'">
            <a-button type="primary" ghost @click="reset">重置</a-button>
          </Tooltip>
        </div>
        <a-button class="gray" @click="closeModal">取消</a-button>
        <a-button type="primary" :disabled="dataList.length === 0" @click="saveModal">确定</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  onMounted, watch,
} from "vue";
import { CloseCircleFilled } from '@ant-design/icons-vue';
import Tooltip from '@/components/Tooltip'
import { useGlobalPropertyHook } from '@/hooks/common'
import { debounce } from 'lodash'
//  左侧搜索框没有值的时候是leftData
const leftData = ref([]);
//  左侧搜索框搜有值的时候则是searchData
const searchData = ref([]);
//  右侧搜索框有值时候取得是searchDataList
const searchDataList = ref([]);

// 右侧已选择的表头
const dataList = ref([])
const customEntryRefModal = ref()
const leftColumns = ref([
  {
    title: "字段名",
    dataIndex: "name",
    key: 'name',
    ellipsis: true,
  },
])
const columns = ref([
  {
    title: "字段名",
    dataIndex: "name",
    key: 'name',
    ellipsis: true,
  },
  {
    title: "操作",
    dataIndex: "option",
    width: 80,
    align: 'center',
    ellipsis: true,
    slots: { customRender: "action" },
    fixed: "right",
  },
])

export default defineComponent({
  name: "ColumnsEntry",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    checkList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  components: { CloseCircleFilled, Tooltip },
  setup(props, ctx) {
    //   const selectedRowKeys = ref([])
    const { $api } = useGlobalPropertyHook();
    /*const state = reactive({
      userData: {}
    })*/
    const getMenus = (type = 'normal') => {
      dataList.value = []

      $api.queryDefaultExcelHeader(0).then((res) => {
        let resData = res.data
        originColumns.forEach((item) => {
          resData.forEach((jtem) => {
            if (item.dataIndex == jtem.firstMenuId) {
              jtem.name = item.title
            }
          })
        })
        leftData.value = resData
        if (type == 'reset') {
          let allCode = res.data.map((item) => { return item.firstMenuId })
          res.data.forEach((item) => {
            dataList.value.push({ firstMenuId: item.firstMenuId, secondMenuId: item.secondMenuId })
          })
          originColumns.forEach((item) => {
            dataList.value.forEach((jtem) => {
              if (item.dataIndex == jtem.firstMenuId) {
                jtem.name = item.title
              }
            })
          })
          checkedKeys.value = [...allCode]
        } else {
          let columnList = JSON.parse(sessionStorage.getItem('ExportDataMoreSortColumns'))
          let filterList = props.checkList.filter((item) => {
            return columnList.includes(item.firstMenuId)
          })
          filterList.forEach((item) => {
            dataList.value.push({ firstMenuId: item.firstMenuId, secondMenuId: item.secondMenuId })
          })
          originColumns.forEach((item) => {
            dataList.value.forEach((jtem) => {
              if (item.dataIndex == jtem.firstMenuId) {
                jtem.name = item.title
              }
            })
          })
          checkedKeys.value = [...columnList]
        }
      });
    };

    let originColumns = [
      {
        title: "Cost Center",
        dataIndex: "costCenter",
        key: "costCenter",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Org3.2",
        dataIndex: "orgThreeTwo",
        key: "orgThreeTwo",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Org4.2",
        dataIndex: "orgFourTwo",
        key: "orgFourTwo",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Org5.1",
        dataIndex: "orgFiveOne",
        key: "orgFiveOne",
        ellipsis: true,
        width: 150,
      },
      {
        title: "GGID",
        dataIndex: "ggid",
        key: "ggid",
        ellipsis: true,
        width: 180,
      },
      {
        title: "ID Number",
        dataIndex: "idNumber",
        key: "idNumber",
        ellipsis: true,
        width: 180,
      },
      {
        title: "Employee Name",
        dataIndex: "employeeName",
        key: "employeeName",
        ellipsis: true,
        width: 180,
      },
      {
        title: "Chinese Name",
        dataIndex: "chineseName",
        key: "chineseName",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Mobile Phone",
        dataIndex: "phoneNo",
        key: "phoneNo",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Capgemini Start Date",
        dataIndex: "onboardDate",
        key: "onboardDate",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Termination Date",
        dataIndex: "terminationDate",
        key: "terminationDate",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Gender Key",
        dataIndex: "gender",
        key: "gender",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Grade",
        dataIndex: "grade",
        key: "grade",
        ellipsis: true,
        width: 100,
      },
      {
        title: "Base Location",
        dataIndex: "baseLocation",
        key: "baseLocation",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Skill Group",
        dataIndex: "departmentSkill",
        key: "departmentSkill",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Main Skillset",
        dataIndex: "skillSet",
        key: "skillSet",
        ellipsis: true,
        width: 150,
      },
      {
        title: "English",
        dataIndex: "english",
        key: "english",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Certificate Name",
        dataIndex: "certificateName",
        key: "certificateName",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Line Manager Name",
        dataIndex: "lmName",
        key: "lmName",
        ellipsis: true,
        width: 200,
      },
      {
        title: "LM Email",
        dataIndex: "lmEmail",
        key: "lmEmail",
        ellipsis: true,
        width: 250,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Availability",
        dataIndex: "availability",
        key: "availability",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Assignment Type",
        dataIndex: "assignmentType",
        key: "assignmentType",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Industry Line",
        dataIndex: "industryLine",
        key: "industryLine",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Account",
        dataIndex: "account",
        key: "account",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Engagement Code",
        dataIndex: "engagementCode",
        key: "engagementCode",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Engagement Description",
        dataIndex: "engagementDescription",
        key: "engagementDescription",
        ellipsis: true,
        width: 250,
      },
      {
        title: "Code有效期（开始时间）",
        dataIndex: "projectStartDate",
        key: "projectStartDate",
        ellipsis: true,
        width: 250,
      },
      {
        title: "Code有效期（结束时间）",
        dataIndex: "projectCloseDate",
        key: "projectCloseDate",
        customRender: ({ record }) => {
          return record.projectStatus === "Close"
            ? record.projectCloseDate
            : record.projectCompletionDate;
        },
        ellipsis: true,
        width: 250,
      },
      {
        title: "EM",
        dataIndex: "engagementName",
        key: "engagementName",
        ellipsis: true,
        width: 150,
      },
      {
        title: "EM Email",
        dataIndex: "engagementEmail",
        key: "engagementEmail",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Leading CSM",
        dataIndex: "leadingCSM",
        key: "leadingCSM",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Leading CSM Email",
        dataIndex: "leadingCSMEmail",
        key: "leadingCSMEmail",
        ellipsis: true,
        width: 200,
      },
      {
        title: "PM",
        dataIndex: "pm",
        key: "pm",
        ellipsis: true,
        width: 150,
      },
      {
        title: "PM Email",
        dataIndex: "pmEmail",
        key: "pmEmail",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Project Location",
        dataIndex: "location",
        key: "location",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Project Onboard Date",
        dataIndex: "assOnBoardDate",
        key: "assOnBoardDate",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Actual Release Date",
        dataIndex: "actualReleaseDate",
        key: "actualReleaseDate",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Estimate Release Date",
        dataIndex: "estimateReleaseDate",
        key: "estimateReleaseDate",
        ellipsis: true,
        width: 220,
      },
      {
        title: "Hours Per Day",
        dataIndex: "hoursPerDay",
        key: "hoursPerDay",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Hours Per Day（Current Term）",
        dataIndex: "currentHoursPerDay",
        key: "currentHoursPerDay",
        ellipsis: true,
        width: 280,
      },
      {
        title: "FCST Extension Assumption Date",
        dataIndex: "extensionAssumptionDate",
        key: "extensionAssumptionDate",
        width: 200,
        slots: { customRender: "extensionAssumptionDate" }
      },
      {
        title: "确定性",
        dataIndex: "certainty",
        key: "certainty",
        width: 100,
        slots: { customRender: "certainty" }
      },
      {
        title: "Last Account",
        dataIndex: "lastAccount",
        key: "lastAccount",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Last Assignment ID",
        dataIndex: "lastAssignmentId",
        key: "lastAssignmentId",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Last Engagement Description",
        dataIndex: "lastEngagementDescription",
        key: "lastEngagementDescription",
        ellipsis: true,
        width: 280,
      },
      {
        title: "Last 调令开始时间",
        dataIndex: "lastOnboardDate",
        key: "lastOnboardDate",
        ellipsis: true,
        width: 180,
      },
      {
        title: "Last 调令结束时间",
        dataIndex: "lastActualReleaseDate",
        key: "lastActualReleaseDate",
        ellipsis: true,
        width: 180,
      },
      {
        title: "Availability Start Date",
        dataIndex: "availabilityStartDate",
        key: "availabilityStartDate",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Bench 时长",
        dataIndex: "bench",
        key: "bench",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Note1",
        dataIndex: "note1",
        key: "note1",
        ellipsis: true,
        width: 100,
      },
      {
        title: "Note2",
        dataIndex: "note2",
        key: "note2",
        ellipsis: true,
        width: 100,
      },
      {
        title: "Note3",
        dataIndex: "note3",
        key: "note3",
        ellipsis: true,
        width: 100,
      }


    ]
    // 左侧搜索框搜索双向绑定
    const leftValue = ref('')
    // 右侧搜索框搜索双向绑定
    const rightValue = ref()
    const selectedKeys = ref();
    const checkedKeys = ref();
    const reset = () => {
      ctx.emit('resetList');
      setTimeout(() => {
        getMenus('reset')
      }, 100)
    }
    const closeModal = () => {
      ctx.emit('closeModal', false);
    }
    const saveModal = () => {
      const params = ref([])
      dataList.value.forEach((item, index) => {
        params.value.push({
          firstMenu: item.firstMenuId,
          secondMenu: item.secondMenuId,
          sort: index + 1 + '',
          headerType: '0',
        })
      })
      $api.saveExcelHeader(params.value).then(() => {
        ctx.emit('closeModal', true);
      })
    }
    // 下面实际上是左侧表格勾选
    const tableSelectChange = (selectedKeys) => {
      checkedKeys.value = selectedKeys
      dataList.value = leftData.value.filter((item) => { return selectedKeys.includes(item.firstMenuId) })
    }
    const clearLeft = debounce(() => {
      searchData.value = []
      onSearchLeft()
    }, 500)

    const onSearchLeft = () => {
      if (leftValue.value) {
        const newList = fuzzyQuery(leftData.value, leftValue.value, 'firstMenuId')
        searchData.value = newList
      }
    }
    const clearRight = debounce(() => {
      searchDataList.value = []
      onSearchRight()
    }, 500)

    const onSearchRight = () => {
      if (rightValue.value) {
        const newList = fuzzyQuery(dataList.value, rightValue.value, 'firstMenuId')
        searchDataList.value = newList
      }
    }
    /**
     * 使用indexof方法实现模糊查询
     * @param  {Array}  array    进行查询的数组
     * @param  {String} string  查询的关键词
     */
    const fuzzyQuery = (array, string) => {
      const filteredArray = array.filter(item => {
        return item.name.toLowerCase().includes(string.toLowerCase());
      })
      return filteredArray;

    }
    const handleDel = (record) => {
      searchDataList.value.filter((item, index) => {
        if (item.firstMenuId === record.firstMenuId) {
          searchDataList.value.splice(index, 1)
        }
      })
      dataList.value.filter((item, index) => {
        if (item.firstMenuId === record.firstMenuId) {
          dataList.value.splice(index, 1)
        }
      })
      let arr = dataList.value.map((item) => {
        return item.firstMenuId
      })
      checkedKeys.value = arr
    }
    const position = {
      start: undefined,
      end: undefined,
      sourceEl: undefined,
    }
    // 排序
    const reorder = ({ start, end }) => {
      if (start !== undefined && end !== undefined) {
        if (start > end) {
          // 当开始大于结束
          let temp = dataList.value[start]
          dataList.value.splice(start, 1);
          dataList.value.splice(end, 0, temp)
        } else if (start < end) {
          // 结束大于开始
          let temp = dataList.value[start]
          dataList.value.splice(start, 1)
          dataList.value.splice(end, 0, temp)
        }
      }
    }

    function rowKey(record) {
      return record.id
    }
    function customRow(_record, index) {
      return {
        style: {
          cursor: "move",
        },
        // 鼠标移入
        onMouseenter: (event) => {
          // 兼容IE
          let ev = event || window.event;
          ev.target.draggable = true;
        },
        // 开始拖拽
        onDragstart: (event) => {
          // 兼容IE
          let ev = event || window.event;
          // 阻止冒泡
          ev.stopPropagation();
          // 得到源目标数据;
          position.start = index
          const tr = ev.target
          position.sourceEl = tr
        },
        // 拖动元素经过的元素
        onDragover: (event) => {
          let ev = event || window.event;
          // 阻止默认行为
          ev.preventDefault();
        },
        // 松开
        onDrop: (event) => {
          let ev = event || window.event;
          // 阻止默认行为
          ev.preventDefault();
          position.end = index
          reorder(position);
          animation(position)
        },
      };
    }
    // 实现动画效果
    function animation({ start, end, sourceEl }) {
      // 48 是每行的高度，也可以自动获取，根据情况而定
      let count = 48 * (start - end)
      sourceEl.style.translate = `0px ${count}px`
      setTimeout(() => {
        sourceEl.style.transition = "all 0.5s"
        sourceEl.style.translate = `0px 0px`
      })
    }
    watch(() => props.visible,
      (val) => {
        if (val) {
          leftValue.value = ''
          rightValue.value = ''
          leftData.value = []
          dataList.value = []
          getMenus()
        }
      },
      { immediate: true }
    );
    onMounted(() => {
    })
    return {
      leftValue,
      rightValue,
      leftData,
      searchData,
      selectedKeys,
      checkedKeys,
      columns,
      leftColumns,
      searchDataList,
      dataList,
      reset,
      closeModal,
      saveModal,
      clearLeft,
      onSearchLeft,
      clearRight,
      onSearchRight,
      handleDel,
      rowKey,
      customRow,
      tableSelectChange,
      customEntryRefModal
    };
  },
});
</script>

<style scoped lang="less">
.box-main {
  display: flex;
  font-size: 16px;
  color: #4d4d4d;

  .box-item {
    flex: 1;

    &.box-left {
      padding-right: 12px;
      border-right: 1px solid #EBEBEB;
    }

    &.box-right {
      padding-left: 12px;
    }

    .tt {
      display: flex;
      align-items: center;
      line-height: 38px;
      font-weight: 500;

      .num {
        margin-left: 12px;
        padding: 0 8px;
        line-height: 24px;
        font-size: 14px;
        color: #999;
        background: #F6F6F6;
        border-radius: 4px;

        &.back-color {
          color: #3182CE;
          background: #E9F6FE;
        }
      }
    }

    .box-margin {
      margin-top: 8px;
    }

    .tree-title {
      padding-left: 30px;
      height: 48px;
      line-height: 48px;
      color: rgba(77, 77, 77, 0.6);
      background: #fafafa;
    }

    .tree-box {
      height: 288px;
      overflow: auto;
    }
  }
}

.btn-left {
  float: left;

  span {
    margin-left: 8px;
  }
}

.gray {
  color: #999 !important;
  border-color: #999 !important;
}

:deep(.ant-table-tbody > tr > td) {
  border: 0;
}

:deep(.ant-table-thead > tr:first-child > th:first-child) {
  padding-left: 30px;
}

:deep(.ant-table .ant-table-tbody > tr > td:first-child) {
  padding-left: 30px;
}

.specialTable {
  :deep(.ant-table-row) {
    background: url("../../../../../assets/iconfont/drap.png") 5px no-repeat;
    background-size: 20px 20px;
  }
}


:deep(.ant-tree) {
  font-size: 16px;
}

:deep(.ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop) {
  display: none;
}

.font-blue {
  color: #3182CE;
}

.icon-btn {
  cursor: pointer;
}
</style>
