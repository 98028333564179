<template>
  <section class="org-list">
    <a-table ref="skillListRef" :columns="columns" :data-source="skillList" :scroll="{
      y: tableHeight,
      x: 1200,
      scrollToFirstRowOnChange: true,
    }" :pagination="pagination">
      <template #operationHead>
        <Tooltip :title="'设置表头'">
          <SettingOutlined style="position:relative;left:5px;font-weight: 700;" class="icon-setting" @click="settingOutHandler"/>
        </Tooltip>
      </template>
      <template #extensionAssumptionDate="{ text }">
        <span>{{ text? text : '-' }}</span>
      </template>
      <template #certainty="{ text }">
        <span>{{ text? text : '-' }}</span>
      </template>
    </a-table>
  </section>
</template>
<script>
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { useTableHeight } from "@/utils/common";
import { useStore } from "vuex";
import { SettingOutlined } from '@ant-design/icons-vue'
import Tooltip from '@/components/Tooltip'
export default defineComponent({
  name: "CostCtrList",
  components: {
    SettingOutlined,
    Tooltip
  },
  emits: ["setting"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const store = useStore();
    const roles = store.state.app.user.roles;

    const skillListRef = ref();

    let originColumns = [
      {
        title: "Cost Center",
        dataIndex: "costCenter",
        key: "costCenter",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Org3.2",
        dataIndex: "orgThreeTwo",
        key: "orgThreeTwo",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Org4.2",
        dataIndex: "orgFourTwo",
        key: "orgFourTwo",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Org5.1",
        dataIndex: "orgFiveOne",
        key: "orgFiveOne",
        ellipsis: true,
        width: 150,
      },
      {
        title: "GGID",
        dataIndex: "ggid",
        key: "ggid",
        ellipsis: true,
        width: 180,
      },
      {
        title: "ID Number",
        dataIndex: "idNumber",
        key: "idNumber",
        ellipsis: true,
        width: 180,
      },
      {
        title: "Employee Name",
        dataIndex: "employeeName",
        key: "employeeName",
        ellipsis: true,
        width: 180,
      },
      {
        title: "Chinese Name",
        dataIndex: "chineseName",
        key: "chineseName",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Mobile Phone",
        dataIndex: "phoneNo",
        key: "phoneNo",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Capgemini Start Date",
        dataIndex: "onboardDate",
        key: "onboardDate",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Termination Date",
        dataIndex: "terminationDate",
        key: "terminationDate",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Gender Key",
        dataIndex: "gender",
        key: "gender",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Grade",
        dataIndex: "grade",
        key: "grade",
        ellipsis: true,
        width: 100,
      },
      {
        title: "Base Location",
        dataIndex: "baseLocation",
        key: "baseLocation",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Skill Group",
        dataIndex: "departmentSkill",
        key: "departmentSkill",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Main Skillset",
        dataIndex: "skillSet",
        key: "skillSet",
        ellipsis: true,
        width: 150,
      },
      {
        title: "English",
        dataIndex: "english",
        key: "english",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Certificate Name",
        dataIndex: "certificateName",
        key: "certificateName",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Line Manager Name",
        dataIndex: "lmName",
        key: "lmName",
        ellipsis: true,
        width: 200,
      },
      {
        title: "LM Email",
        dataIndex: "lmEmail",
        key: "lmEmail",
        ellipsis: true,
        width: 250,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Availability",
        dataIndex: "availability",
        key: "availability",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Assignment Type",
        dataIndex: "assignmentType",
        key: "assignmentType",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Industry Line",
        dataIndex: "industryLine",
        key: "industryLine",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Account",
        dataIndex: "account",
        key: "account",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Engagement Code",
        dataIndex: "engagementCode",
        key: "engagementCode",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Engagement Description",
        dataIndex: "engagementDescription",
        key: "engagementDescription",
        ellipsis: true,
        width: 250,
      },
      {
        title: "Code有效期（开始时间）",
        dataIndex: "projectStartDate",
        key: "projectStartDate",
        ellipsis: true,
        width: 250,
      },
      {
        title: "Code有效期（结束时间）",
        dataIndex: "projectCloseDate",
        key: "projectCloseDate",
        customRender: ({ record }) => {
          return record.projectStatus === "Close"
            ? record.projectCloseDate
            : record.projectCompletionDate;
        },
        ellipsis: true,
        width: 250,
      },
      {
        title: "EM",
        dataIndex: "engagementName",
        key: "engagementName",
        ellipsis: true,
        width: 150,
      },
      {
        title: "EM Email",
        dataIndex: "engagementEmail",
        key: "engagementEmail",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Leading CSM",
        dataIndex: "leadingCSM",
        key: "leadingCSM",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Leading CSM Email",
        dataIndex: "leadingCSMEmail",
        key: "leadingCSMEmail",
        ellipsis: true,
        width: 200,
      },
      {
        title: "PM",
        dataIndex: "pm",
        key: "pm",
        ellipsis: true,
        width: 150,
      },
      {
        title: "PM Email",
        dataIndex: "pmEmail",
        key: "pmEmail",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Project Location",
        dataIndex: "location",
        key: "location",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Project Onboard Date",
        dataIndex: "assOnBoardDate",
        key: "assOnBoardDate",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Actual Release Date",
        dataIndex: "actualReleaseDate",
        key: "actualReleaseDate",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Estimate Release Date",
        dataIndex: "estimateReleaseDate",
        key: "estimateReleaseDate",
        ellipsis: true,
        width: 220,
      },
      {
        title: "Hours Per Day",
        dataIndex: "hoursPerDay",
        key: "hoursPerDay",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Hours Per Day（Current Term）",
        dataIndex: "currentHoursPerDay",
        key: "currentHoursPerDay",
        ellipsis: true,
        width: 280,
      },
      {
        title: "FCST Extension Assumption Date",
        dataIndex: "extensionAssumptionDate",
        key: "extensionAssumptionDate",
        width: 200,
        slots: { customRender: "extensionAssumptionDate" }
      },
      {
        title: "确定性",
        dataIndex: "certainty",
        key: "certainty",
        width: 100,
        slots: { customRender: "certainty" }
      },
      {
        title: "Last Account",
        dataIndex: "lastAccount",
        key: "lastAccount",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Last Assignment ID",
        dataIndex: "lastAssignmentId",
        key: "lastAssignmentId",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Last Engagement Description",
        dataIndex: "lastEngagementDescription",
        key: "lastEngagementDescription",
        ellipsis: true,
        width: 280,
      },
      {
        title: "Last 调令开始时间",
        dataIndex: "lastOnboardDate",
        key: "lastOnboardDate",
        ellipsis: true,
        width: 180,
      },
      {
        title: "Last 调令结束时间",
        dataIndex: "lastActualReleaseDate",
        key: "lastActualReleaseDate",
        ellipsis: true,
        width: 180,
      },
      {
        title: "Availability Start Date",
        dataIndex: "availabilityStartDate",
        key: "availabilityStartDate",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Bench 时长",
        dataIndex: "bench",
        key: "bench",
        ellipsis: true,
        width: 150,
      },
      {
        dataIndex: 'operationHead',
        key: 'operationHead',
        width: 60,
        fixed: "right",
        slots: {
          title: 'operationHead',
        },
      },

    ]

    // 下部分内容为占位不重要
    let columns = ref([
      {
        title: "Cost Center",
        dataIndex: "costCenter",
        key: "costCenter",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Org3.2",
        dataIndex: "orgThreeTwo",
        key: "orgThreeTwo",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Org4.2",
        dataIndex: "orgFourTwo",
        key: "orgFourTwo",
        ellipsis: true,
        width: 200,
      },
    ])
    columns.value.push(
        {
          title: "Note1",
          dataIndex: "note1",
          key: "note1",
          ellipsis: true,
          width: 100,
        },
        {
          title: "Note2",
          dataIndex: "note2",
          key: "note2",
          ellipsis: true,
          width: 100,
        },
        {
          title: "Note3",
          dataIndex: "note3",
          key: "note3",
          ellipsis: true,
          width: 100,
        }
    );
    originColumns.push({
        title: "Note1",
        dataIndex: "note1",
        key: "note1",
        ellipsis: true,
        width: 100,
      },
        {
          title: "Note2",
          dataIndex: "note2",
          key: "note2",
          ellipsis: true,
          width: 100,
        },
        {
          title: "Note3",
          dataIndex: "note3",
          key: "note3",
          ellipsis: true,
          width: 100,
    })
    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12', '20', '30', '40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        doSearch(searchCondition);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        doSearch(searchCondition);
      },
    });
    const skillList = ref();
    let searchCondition = {};

    const doSearch = (searchParams = searchCondition) => {
      const params = {
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        ...searchParams,
      };
      $api.resourceExportList(params).then((res) => {
        pagination.total = res.data.total;
        skillList.value = res.data.resource;
        // 过滤列
        let columnList = JSON.parse(sessionStorage.getItem('ExportDataMoreSortColumns'))
        let allList = [
          ...sortByOrder(originColumns.filter((item) => { return columnList.includes(item.key) }), columnList),
          {
            dataIndex: 'operationHead',
            key: 'operationHead',
            width: 60,
            fixed: "right",
            slots: {
              title: 'operationHead',
            },
          },
        ]
        let mainAllWitdh = allList.reduce((all, item) => { return all + item.width }, 0)
        if (1200 > mainAllWitdh) {
          let buffer = Math.floor((1200 - mainAllWitdh) / (allList.length - 1))
          let anotherList = [...JSON.parse(JSON.stringify(allList))]
          anotherList.forEach((item, index) => {
            if (index != allList.length - 1) {
              item.width = item.width + buffer
            }
          })
          columns.value = anotherList
        } else {
          columns.value = allList
        }
      });
    };
    //arr1 要排序的数组，arr2 排序的准则顺序
    const sortByOrder = (arr1, arr2) => {
      return arr1.sort((a, b) => {
        const indexA = arr2.indexOf(a.dataIndex);
        const indexB = arr2.indexOf(b.dataIndex);
        return indexA - indexB;
      });
    }

    const searchClick = (searchParams) => {
      pagination.current = 1;
      searchCondition = searchParams;
      doSearch(searchCondition);
    };

    const settingOutHandler = () => {
      ctx.emit("setting");
    }


    const tableHeight = ref(0);

    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);
    onMounted(() => {
      getSize();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    return {
      skillListRef,
      columns,
      pagination,
      skillList,
      searchClick,
      tableHeight,
      doSearch,
      roles,
      settingOutHandler
    };
  },
});
</script>
<style scoped lang="less">
// 覆盖ant-design样式
::v-deep .ant-table-body {
  overflow: auto;
}

:deep(.ant-table-thead) {
  >tr>th {
    white-space: nowrap;
  }
}

:deep(.ant-table-tbody) {
  >tr>td {
    white-space: nowrap;
  }
}

.org-list {
  display: flex;
  width: 100%;
  max-height: calc(100% - 88px);

  .ant-table-wrapper {
    flex: 1;
    width: 0;
  }

  .org-info {
    width: 300px;
    //margin-left: 20px;
    background: #f4f4f4;
  }
}
</style>
