<template>
  <section class="top-filter">
    <div class="mb12">
      <FilterLayout @doReset="resetForm" @doSearch="doSearch" ref="filterLayoutRef">
        <template #customform>
          <a-form ref="formRef" class="form rms-form" layout="vertical" :model="formState">
            <a-form-item label="搜索" name="condition" class="rms-form-item">
              <a-input v-model:value="formState.condition" placeholder="Name/GGID" allowClear autocomplete="off" />
            </a-form-item>
            <a-form-item label="Team" name="orgThreeTwoList" class="rms-form-item">
              <SelectWithAll v-model:value="formState.orgThreeTwoList" placeholder="请选择" mode="multiple" showArrow
                allowClear :maxTagCount="1" @change="orgThreeTwoChange">
                <a-select-option v-for="(item, index) in orgThreeTwoOps" :key="index" :value="item"><span :title="item">{{
                  item }}</span>
                </a-select-option>
              </SelectWithAll>
            </a-form-item>
            <a-form-item label="Offering" name="orgFourOneOneList" class="rms-form-item">
              <SelectWithAll v-model:value="formState.orgFourOneOneList" placeholder="请选择" mode="multiple" showArrow
                allowClear :maxTagCount="1">
                <a-select-option v-for="(item, index) in orgFourOneOneOps" :key="index" :value="item"><span
                    :title="item">{{ item }}</span>
                </a-select-option>
              </SelectWithAll>
            </a-form-item>
            <a-form-item label="Assignment Type" name="assignmentType" class="rms-form-item">
              <a-select v-model:value="formState.assignmentType" placeholder="请选择" :options="assignmentTypeOps"
                allowClear />
            </a-form-item>
          </a-form>
        </template>
        <template #operationBtns>
          <div class="buttons-wrap right">
            <Tooltip title="导出">
              <a-button class="tight" type="primary" ghost @click="exportClick" v-auth="['system:exportData:export']">
                <ExportOutlined />
              </a-button>
            </Tooltip>
          </div>
        </template>
      </FilterLayout>
    </div>
    <ColumnsEntry v-model:visible="visible" :checkList="checkList" @closeModal="closeModal" @resetList="checkListInit" />
  </section>
</template>
<script>
import { defineComponent, reactive, ref, onMounted } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import ColumnsEntry from '@/views/home/ResourceManagement/ExportData/components/ColumnsEntry.vue'
import { ExportOutlined } from "@ant-design/icons-vue";
import Tooltip from '@/components/Tooltip'

export default defineComponent({
  name: "TopFilter",
  components: {
    Tooltip,
    ExportOutlined,
    ColumnsEntry
  },
  emits: ["search", "export-click", "refresh-table"],
  setup(props, ctx) {
    const visible = ref(false);
    const { $api } = useGlobalPropertyHook();
    const formRef = ref();
    let currentParams = {}
    //只有在点击查询按钮时才更新筛选项
    const updateParams = () => {
      currentParams = {
        orgThreeTwoList: formState.orgThreeTwoList,
        orgFourOneOneList: formState.orgFourOneOneList,
        assignmentType: formState.assignmentType,
        condition: formState.condition,
      }
    }

    const orgThreeTwoOps = ref([]);
    const orgFourOneOneOps = ref([]);
    const assignmentTypeOps = ref([]);
    const formState = reactive({
      orgThreeTwoList: [],
      orgFourOneOneList: [],
      assignmentType: undefined,
      condition: ""
    });
    const handleSetting = () => {
      visible.value = true
    }
    const closeModal = (flag) => {
      visible.value = false
      // flag为true则进行保存
      if (flag) {
        getQuickEntry('1')
      }

    }
    const checkListInit = () => {
      $api.queryExcelSort(0).then((res) => {
        checkList.value = [].concat([...res.data.sort(sortBy('sort'))])
      })
    }
    const checkList = ref([])
    const getQuickEntry = (flag) => {
      $api.queryExcelSort(flag).then((res) => {
        checkList.value = [].concat([...res.data.sort(sortBy('sort'))])
        sessionStorage.setItem('ExportDataMoreSortColumns', JSON.stringify(
          checkList.value.map((item) => { return item.firstMenuId })
        ))
        updateParams()
        ctx.emit("refresh-table",currentParams)
      });
    };
    const sortBy = (field) => {
      //根据传过来的字段进行排序
      return (x, y) => {
        return x[field] - y[field]
      }
    }

    const resetForm = () => {
      Object.assign(formState, {
        orgThreeTwoList: [],
        orgFourOneOneList: [],
        assignmentType: undefined,
        condition: ""
      })
      updateParams()
      getOrgList()
      ctx.emit("search", currentParams);
    };
    const getOrgList = () => {
      //查询org3.2/org4.2
      $api.getOrgThree_FourList().then((res) => {
        const { orgThreeTwo, orgFourOneOne } = res.data;
        orgThreeTwoOps.value = orgThreeTwo;
        orgFourOneOneOps.value = orgFourOneOne;
      });
    };
    const orgThreeTwoChange = () => {
      formState.orgFourOneOneList = [];
      if (formState.orgThreeTwoList.length) {
        $api
          .getOrgFour({ condition: formState.orgThreeTwoList.join() })
          .then((res) => {
            orgFourOneOneOps.value = res.data.orgFourOneOne;
          });
      } else {
        getOrgList();
      }
    };
    const doSearch = () => {
      updateParams()
      ctx.emit("search", currentParams);
    };
    const exportClick = () => {
      ctx.emit("export-click", currentParams);
    };
    const getAssignmentTypes = () => {
      $api
        .getDictionaryList({
          params: {
            dictType: "assignmentType",
          },
        })
        .then((res) => {
          assignmentTypeOps.value = (res.data?.assignmentType || []).map(({ dictCode, dictValue }) => ({
            label: dictValue,
            value: dictCode,
          }));
        });
    };
    const show = ref(true);
    onMounted(() => {
      // nextTick(doSearch);
      getAssignmentTypes();
      getOrgList();
      getQuickEntry('1');
    });

    return {
      formState,
      formRef,
      updateParams,
      doSearch,
      resetForm,
      exportClick,
      orgThreeTwoOps,
      orgFourOneOneOps,
      assignmentTypeOps,
      orgThreeTwoChange,
      show,
      handleSetting,
      closeModal,
      visible,
      getQuickEntry,
      checkList,
      checkListInit
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;
}

.buttons-wrap {
  :deep(.anticon) {
    font-size: 20px;
  }
}
</style>
